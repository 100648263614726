import { connect } from 'react-redux'
import SearchBar from './SearchBar.jsx'
import { getFilterLoadingState, getDataLoaded, getCount } from '../../selectors/search-filter-selectors.js'
import { getSchedulingType } from '../../../shared/selectors/shared-scheduling-selectors'
import { insertBrowserHistory } from '../../state/history'
import { getURLParameters, insertEmptyFilters } from '../../../shared/utility-functions'

const mapStateToProps = (state, ownProps) => ({
  allSearchedDoctorsCount: ownProps.allSearchedDoctorsCount,
  searchQuery: state.searchInput.searchQuery,
  searchLocation: state.searchInput.searchLocation,
  searchOptions: ownProps.searchOptions,
  filtersLoading: getFilterLoadingState(state),
  lookingFor: getURLParameters().search.q ? getURLParameters().search.q : state.searchInput.searchQuery,
  address: getURLParameters().search.locq ? getURLParameters().search.locq : state.searchInput.searchLocation.address,
  latitude: getURLParameters().search.lat ? Number(getURLParameters().search.lat) : state.searchInput.searchLocation.latitude,
  longitude: getURLParameters().search.lng ? Number(getURLParameters().search.lng) : state.searchInput.searchLocation.longitude,
  within: getURLParameters().search.dist ? Number(getURLParameters().search.dist) : state.searchInput.searchLocation.within,
  numberOfSearchItems: getCount(state),
  dataLoaded: getDataLoaded(state),
  showAffiliatedDoctors: state.doctorSearch.showAffiliatedDoctors,
  flow: getSchedulingType(state),
  fetchCallFailed: state.doctorSearch.callFailed,
  orgID: ownProps.orgID,
  addressKeys: ownProps.addressKeys,
  itemIds: ownProps.itemIds,
  app: ownProps.app,
  visitTypeId: state.searchInput.visitTypeId,
  fadSearchedBounds: (state.fadMap && state.fadMap.searchedBounds),
  fadAutoSearchedLocation: (state.fadMap && state.fadMap.autoSearchedLocation) ? state.fadMap.autoSearchedLocation : null
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSearch: (params, isBlock, noLoad) => dispatch({
    type: 'GET_FAD_PROVIDERS',
    params: params,
    isBlock: isBlock,
    noLoad: noLoad
  }),
  dispatchMAMSearch: (params, isBlock) => dispatch({
    type: 'GET_MAM_LOCATIONS',
    params: params,
    isBlock: isBlock
  }),
  resetFilters: () => dispatch({
    type: 'RESET_SCHEDULE_A_DOCTOR_FILTERS'
  }),
  setSort: (sortOrder) => dispatch({
    type: 'SET_DOCTOR_SEARCH_SORT_ORDER',
    value: sortOrder
  }),
  dispatchLocationSearch: (params) => dispatch({
    type: 'GET_LOCATIONS',
    params: params
  }),
  dispatchServiceSearch: (params) => dispatch({
    type: 'GET_SERVICES',
    params: params
  })
})

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  runSearch: (params, noLoadIn) => {
    params.org = stateProps.orgID
    params.app = stateProps.app
    params.addKey = stateProps.addressKeys
    params.ids = stateProps.itemIds
    if (stateProps.flow === 'fad' || stateProps.flow === 'sad' || stateProps.flow === 'cin') {
      let oldQuery = stateProps.searchQuery
      if (oldQuery !== params.q) {
        dispatchProps.resetFilters()
        dispatchProps.setSort('A TO Z')
        if (stateProps.searchOptions && stateProps.searchOptions.enableUrl) {
          insertBrowserHistory(insertEmptyFilters(getURLParameters()))
        }
      }
      let noLoad = noLoadIn ? noLoadIn : false
      dispatchProps.dispatchSearch(params, stateProps.searchOptions.isBlock, noLoad)
    } else if (stateProps.flow === 'mam') {
      dispatchProps.dispatchMAMSearch(params, stateProps.searchOptions.isBlock)
    } else if (stateProps.flow === 'location') {
      dispatchProps.dispatchLocationSearch(params)
    } else if (stateProps.flow === 'service') {
      dispatchProps.dispatchServiceSearch(params)
    }
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SearchBar)
