import React from 'react'
import infoIcon from '../../../shared/assets/info.svg'
import SVGInline from 'react-svg-inline'
import { scheduleQuestionEvent } from '../../../shared/utility-functions/googleTagManager'
import './questions-modal.scss'

export default class QuestionsModal extends React.Component {
  constructor (props) {
    super(props)
    this.actionHandler = this.actionHandler.bind(this)
    this.state = {
      totalQuestions: this.props.service.numberOfQuestions,
      currentQuestion: this.props.service.getCurrentQuestion()
    }
  }

  componentDidMount () {
    let answer = document.querySelector('button.answer')
    if (answer) {
      answer.focus()
    }
  }

  actionHandler (answer) {
    const dataLayerObject = {
      flow: this.props.flow,
      scheduleQuestionNumber: undefined,
      scheduleQuestion: this.state.currentQuestion.question,
      scheduleQuestionAnswer: answer.text
    }

    scheduleQuestionEvent(dataLayerObject)

    if (answer.error) {
      this.props.submissionHandler(answer)
    } else {
      this.props.service.answerQuestion(answer)
      this.setState({ currentQuestion: this.props.service.getCurrentQuestion() })
      if (this.props.service.getCurrentQuestion() === 'done') {
        this.props.submissionHandler()
      } else {
        let answer = document.querySelector('button.answer')
        if (answer) {
          answer.focus()
        }
      }
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.title !== this.props.title || prevProps.service.getCurrentQuestion().question !== this.props.service.getCurrentQuestion().question) {
      this.setState({
        totalQuestions: this.props.service.numberOfQuestions,
        currentQuestion: this.props.service.getCurrentQuestion()
      })
    }
    if (prevState.currentQuestion !== this.state.currentQuestion) {
      document.activeElement.blur()
    }
  }

  render () {
    let positionValue = (this.state.currentQuestion.number % 2 === 0) ? 'relative' : 'initial' // doing this for a safari bug to force a redraw of question text
    return (
      <div className='questions-modal'>
        {this.state.currentQuestion.type && this.state.currentQuestion.type === 'notice' && <SVGInline svg={infoIcon} className='error-icon' />}
        <div className='title'>{this.state.currentQuestion.title ? this.state.currentQuestion.title : this.props.title}</div>
        {this.state.currentQuestion.type !== 'notice' && this.state.totalQuestions > 1 && <p className='step-numbers'>{this.state.currentQuestion.number} of {this.state.totalQuestions}</p>}
        <p className='question' style={{position: positionValue}}>{this.state.currentQuestion.question}</p>
        {
          this.state.currentQuestion && this.state.currentQuestion.answers && this.state.currentQuestion.answers.map((answer, index) => {
            return <button key={index} className={`btn btn-primary answer question-number-${this.state.currentQuestion.number} ${answer.className ? answer.className : ''} button-${index}`} onClick={() => this.actionHandler(answer)}>{answer.text}</button>
          })
        }
      </div>
    )
  }
}
