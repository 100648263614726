import React, { useState } from 'react'
import URLS from '../../../../shared/constants/urls.js'
import './SuperLocationCardAlt.scss'
import DirectionsLink from '../../../../shared/components/DirectionsLink/index'
import SVGInline from 'react-svg-inline'
import { cleanNumber } from '../../../../shared/utility-functions'
import LocationIcon from '../../../../shared/assets/map-marker.svg'
import InfoIcon from '../../../../shared/assets/info-reviews-drawer.svg'
import SuperResultsTimesConnector from './SuperResultsTimes/SuperResultsTimesConnector.jsx'
import { buildProfilePageUrl } from '../../../../shared/utility-functions'
import ProviderDrawerConnector from '../../DynamicDrawers/ProviderDrawer/ProviderDrawerConnector.jsx'
import TimesDrawer from '../../DynamicDrawers/TimesDrawer'
import ReviewsDrawerConnector from '../../DynamicDrawers/ReviewsDrawer/ReviewsDrawerConnector.jsx'
import { clickToCallTransformer } from '../../../../shared/data-mappers/click-to-call-transformer'
import { clickToCallEvent } from '../../../../shared/utility-functions/googleTagManager.js'
import isDesktop from '../../../../shared/utility-functions/isDesktop'

const SuperLocationCardAlt = ({ location, selectedProvider, updateLocationIconStyle, preliminaryStatus, setPreliminaryStatus, options, scheduleLinkClick, profileLinkClick, flow, active = null, onClickLoc }) => {
    let type = location.AddressKey ? 'loc-feed' : 'fad-feed'
    let name = location.Name ? location.Name : location.lbl
    if (location.SearchNameOverride && location.SearchNameOverride !== '') { name = location.SearchNameOverride}
    let dist = location.customDistance ? location.customDistance : location.d ? location.d : null
    let addressLine1 = location.AddressLine1 ? location.AddressLine1 : location.adr
    let addressLine2 = location.AddressLine2 ? location.AddressLine2 : null
    let cityState = location.City ? `${location.City}, ${location.State}` : `${location.c}, ${location.s}`
    let phone = location.Phone ? location.Phone : null
    let directionsText
    if (dist !== null && dist > 0) {
        directionsText = `${dist} miles - ${addressLine1} ${cityState}`
    } else {
        directionsText = `${addressLine1} ${cityState}`
    }
    if (addressLine2 !== null) {
        directionsText = directionsText + ' ' + addressLine2
    }
    let mobileAddressLine = addressLine1 + ', ' + cityState
    if (mobileAddressLine.length > 43) {
        mobileAddressLine = mobileAddressLine.substring(0, 40) + '...'
    }
   

    if (location.cr && location.cr.length > 0) {
        if (location.cr[0].type === 'Phone') { phone = location.cr[0].Number}
    }

    const cardOptions = {
        hidePractice: options && options.hidePractice === true,
        hidePhone: options && options.hidePhone === true,
        hidePhoto: options && options.hidePhoto === true,
        hideScheduling: options && options.hideScheduling === true,
        hideRatings: options && options.hideRatings === true,
        profileRoot: options && options.profileRoot ? options.profileRoot : null,
        displayAvailability: options && options.displayAvailability === true
    }

    let profilePageURL
    if (!cardOptions.profileRoot) {
        let rootUrl = window.location.hostname && window.location.hostname.indexOf('test') > -1 ? 'test.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('dev') > -1 ? 'dev.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('stage') > -1 ? 'stage.ohiohealth.com' : 'ohiohealth.com'))
        profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${window.location.hostname === 'localhost' ? 'localhost' : rootUrl}${URLS.findADoctorUrl}${URLS.profileUrl}/`, selectedProvider.title, selectedProvider.npi)
    } else {
        profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${cardOptions.profileRoot}/`, selectedProvider.title, selectedProvider.npi)
    }

    // console.log('directionsText -> ', directionsText)
    // console.log('location -> ', location)
    // console.log('selectedProvider -> ', selectedProvider)

    const [showQuestions, setShowQuestions] = useState(false)
    const [providerDrawerShow, setProviderDrawerShow] = useState(false)
    const [timesDrawerShow, setTimesDrawerShow] = useState(false)
    const [reviewsDrawerShow, setReviewsDrawerShow] = useState(false)
    const [fadeOutType, setFadeOutType] = useState('none')
    const [fadeInType, setFadeInType] = useState('none')

    const addressBuilder = (address) => {
        if (type === 'loc-feed') {
            return {
                street: address.AddressLine1,
                cityStateZip: `${address.City}, ${address.State} ${address.ZipCode}`
            }
        }
        else {
            return {
                street: address.adr,
                cityStateZip: `${address.c}, ${address.s} ${address.z}`
            }
        }
    }

    const removeRootScroll = () => {
        document.documentElement.classList.add('no-scroll-y')
    }

    const addRootScroll = () => {
        document.documentElement.classList.remove('no-scroll-y')
    }

    const openDrawer = (option) => {
        removeRootScroll()
        profileLinkClick(selectedProvider)
        setProviderDrawerShow(true)
        if (option === 'times') {
            setFadeInType('card')
            setTimesDrawerShow(true)
        } else if (option === 'reviews') {
            setFadeInType('card')
            setReviewsDrawerShow(true)
        }
    }

    const closeAllDrawers = () => {
        setProviderDrawerShow(false)
        setTimesDrawerShow(false)
        setReviewsDrawerShow(false)
        addRootScroll()
    }
    
    const clickToCall = () => {
        clickToCallEvent(clickToCallTransformer(flow, selectedProvider))
    }

    const onDrawerAnimateEnd = () => {
        if (fadeOutType === 'provider') {
            setProviderDrawerShow(false)
            addRootScroll()
        } else if (fadeOutType === 'times') {
            setTimesDrawerShow(false)
        } else if (fadeOutType === 'reviews') {
            setReviewsDrawerShow(false)
        } else if (fadeOutType === 'all') {
            closeAllDrawers()
        }
        setFadeInType('none')
        setFadeOutType('none')
    }

    const renderDrawers = () => {
        if (providerDrawerShow || timesDrawerShow || reviewsDrawerShow) {
            return (
                <span className='drawers'>
                    <ProviderDrawerConnector provider={selectedProvider} profilePageURL={profilePageURL} distance={dist} options={options} show={providerDrawerShow} cardOptions={cardOptions} preliminaryStatus={preliminaryStatus} setPreliminaryStatus={setPreliminaryStatus}
                        fadeOutType={fadeOutType}
                        handleClose={() => { setFadeOutType('provider') }}
                        handleCloseAll={() => { setFadeOutType('all') }}
                        onAnimationEnd={() => { onDrawerAnimateEnd() }}
                        timesDrawerClick={() => { setFadeInType('drawer'); setTimesDrawerShow(true) }}
                        reviewsDrawerClick={() => { setFadeInType('drawer'); setReviewsDrawerShow(true) }}
                        timesDrawerShow={timesDrawerShow}
                        reviewsDrawerShow={reviewsDrawerShow}
                        from={'provider-drawer'}
                        setShowQuestions={setShowQuestions}
                    />
                    <TimesDrawer provider={selectedProvider} from={fadeInType} show={timesDrawerShow} scheduleLinkClick={scheduleLinkClick}
                        fadeOutType={fadeOutType} flow={flow}
                        handleClose={() => { setFadeOutType('times') }}
                        handleCloseAll={() => { setFadeOutType('all') }}
                        onAnimationEnd={() => { onDrawerAnimateEnd() }}
                    />
                    <ReviewsDrawerConnector from={fadeInType} distance={dist} options={options} show={reviewsDrawerShow} cardOptions={cardOptions}
                        fadeOutType={fadeOutType}
                        handleClose={() => { setFadeOutType('reviews') }}
                        handleCloseAll={() => { setFadeOutType('all') }}
                        onAnimationEnd={() => { onDrawerAnimateEnd() }}
                    />
                </span>
            )
        }
        return null
    }

    const onCardClick = () => {
        if (window.innerWidth >= 1200) {
            return
        }
        if (active) {
            onClickLoc(null)
        }
        else {
            onClickLoc(location)
        }
    }

    return (
        <div className={`super-location-card-container-alt ${active ? 'active' : ''}`}
            onClick={onCardClick}
            onMouseEnter={() => { window.innerWidth >= 1200 ? (type === 'loc-feed' ? updateLocationIconStyle(location.AddressKey, true) : updateLocationIconStyle(location.id, true)) : null}}
            onMouseLeave={() => { window.innerWidth >= 1200 ? (type === 'loc-feed' ? updateLocationIconStyle(location.AddressKey, false) : updateLocationIconStyle(location.id, false)) : null }}>

            {renderDrawers()}

            <div className='super-location-info-container'>
                <div className='super-location-img-wrapper'>
                    {location.ImageUrl != null
                        ? <img class='super-location-img' src={`https://www.ohiohealth.com/${location.ImageUrl}`} alt={name} aria-label={name} />
                        : <img class='super-location-img' src={`https://www.ohiohealth.com/${URLS.defaultLocationImage}`} alt="default location image" aria-label={name} />
                    }
                </div>
                <div className='super-location-info'>
                    <div className='super-location-name'>{name}</div>
                    <div className='super-location-address'>
                        <DirectionsLink address={addressBuilder(location)} text={directionsText} displayIcon={false} />
                    </div>

                    <div className='super-location-phone-number'>
                        {phone != null && phone !== '' && <span>{phone}</span>}
                    </div>
                </div>
            </div>
            <a className='super-results-address--container-alt ' href={`https://maps.apple.com/?q=${addressLine1}+${cityState}`} target='_blank'>
                <SVGInline className='location-icon' svg={LocationIcon} />
                {mobileAddressLine}
            </a>
            {location.EpicID && location.EpicID !== null ?
                <SuperResultsTimesConnector
                    preliminaryStatus={preliminaryStatus}
                    setPreliminaryStatus={setPreliminaryStatus}
                    provider={selectedProvider}
                    profilePageURL={profilePageURL}
                    timesDrawerClick={() => { openDrawer('times') }}
                    handleCloseAll={() => { setFadeOutType('all') }}
                    from={'card'}
                    setShowQuestions={setShowQuestions}
                    superLocationCardAltLocation={location}
                    superLocationCardAltLocationPhone={phone}
                /> :
                <div className='cannot-schedule-text '>
                    {isDesktop(window.innerWidth) &&
                        <div>
                            <p>Online scheduling is currently not available at this location. Choose another location or call the office to schedule.</p>
                            <div className='super-location-phone-number'>
                                {phone != null && phone !== '' && <span>{phone}</span>}
                            </div>
                        </div>
                    }

                    {!isDesktop(window.innerWidth) &&
                        <div className='border-top'>
                            <div className='cannot-schedule-text-mobile'>
                                <span>
                                    <SVGInline svg={InfoIcon} className='info-icon' />
                                    <span className='info-text'>Online scheduling is currently not available at this location. Choose another location or <a href={`tel:${cleanNumber(phone)}`} onClick={() => clickToCall()}>call the office to schedule.</a></span>
                                </span>
                            </div>
                        </div>
                    }
                </div>
            }

        </div>
    )
}

export default SuperLocationCardAlt