import React from 'react'
import SVGInline from 'react-svg-inline'
import URLS from '../../../../shared/constants/urls'
import InfoBadge from '../../../../shared/components/InfoBadge'
import StarBar from '../../../../shared/components/StarBar'
import mapMarker from '../../../../shared/assets/directions.svg'
import playButton from '../../../../shared/assets/Play_ol.svg'
import moment from 'moment'
import isCardio from '../../../../shared/utility-functions/isCardio'
import { isOBGYN } from '../../../../shared/utility-functions/isOBGYN'
import { buildProfilePageUrl, buildDoctorUrl, getURLParameters, cleanNumber, launchVideo } from '../../../../shared/utility-functions'
import { insertBrowserHistory } from '../../../state/history'
import './provider-card.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { scheduleButtonEvent, clickToCallEvent } from '../../../../shared/utility-functions/googleTagManager'

const ProviderCard = ({ provider, distance, pageNumber, scheduleLinkClick, cardioScheduleLinkClick, obgynScheduleLinkClick, options, sort, flow }) => {
  const cardOptions = {
    hidePractice: options && options.hidePractice === true,
    hidePhone: options && options.hidePhone === true,
    hidePhoto: options && options.hidePhoto === true,
    hideScheduling: options && options.hideScheduling === true,
    hideRatings: options && options.hideRatings === true,
    profileRoot: options && options.profileRoot ? options.profileRoot : null,
    displayAvailability: options && options.displayAvailability === true
  }
  const rating = provider.rating
  const hasScheduleOnline = cardOptions.hideScheduling ? false : provider.hasScheduleOnline
  const cardioProvider = provider ? isCardio(provider.specialtyIds) : false
  const obgynProvider = provider ? isOBGYN(provider.specialtyIds) : false
  const hasDoctorPhoto = provider.photoUrl != null
  let phone = null
  if (provider) {
    if (provider.locations && provider.locations.length === 1) {
      phone = provider.locations[0].phoneNumber
    } else {
      phone = provider.phone
    }
  }

  const openSchedulingURL = buildDoctorUrl('/schedule/', { name: provider.title, npi: provider.npi })
  let profilePageURL
  if (!cardOptions.profileRoot) {
    let rootUrl = window.location.hostname && window.location.hostname.indexOf('test') > -1 ? 'test.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('dev') > -1 ? 'dev.ohiohealth.com' : (window.location.hostname && window.location.hostname.indexOf('stage') > -1 ? 'stage.ohiohealth.com' : 'ohiohealth.com'))
    profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${window.location.hostname === 'localhost' ? 'localhost' : rootUrl}${URLS.findADoctorUrl}${URLS.profileUrl}/`, provider.title, provider.npi)
  } else {
    profilePageURL = buildProfilePageUrl(`${window.location.protocol}//${cardOptions.profileRoot}/`, provider.title, provider.npi)
  }

  const scheduleClick = () => {
    const dataLayerObject = {
      flow: flow,
      providerName: provider.title,
      providerID: provider.id,
      providerNPI: provider.npi,
      providerSpecialty: provider.specialties ? provider.specialties.join(', ') : undefined,
      facilityName: undefined,
      facilityAddress: undefined
    }

    scheduleButtonEvent(dataLayerObject)

    if (options && options.enableUrl && sort !== 'Random') {
      insertBrowserHistory(getURLParameters(), pageNumber, provider.npi)
    }
    if (cardioProvider) {
      cardioScheduleLinkClick(openSchedulingURL)
    } else if (obgynProvider) {
      obgynScheduleLinkClick(openSchedulingURL, phone, provider)
    } else {
      scheduleLinkClick(openSchedulingURL)
    }
    window.sessionStorage.setItem('search-path', window.location.href)
  }

  const clickToCall = () => {
    const dataLayerObject = {
      flow: flow,
      providerPhoneNumber: provider.phone,
      providerName: provider.title,
      providerID: provider.id,
      providerNPI: provider.npi,
      providerSpecialty: provider.specialties ? provider.specialties.join(', ') : undefined,
      providerAddress: provider.address ? provider.address.adr + ', ' + provider.address.c + ' ' + provider.address.s + ' ' + provider.address.z : undefined,
      facilityName: undefined,
      facilityAddress: undefined
    }

    clickToCallEvent(dataLayerObject)
  }

  const profileClick = () => {
    if (options && options.enableUrl && sort !== 'Random') {
      insertBrowserHistory(getURLParameters(), pageNumber, provider.npi)
    }
  }

  const renderDistanceAndOrRatings = () => {
    return distance || (rating && rating.Rating) ? (
      <div className='distance-rating-container'>
        {
          distance &&
          <div className='distance-container'>
            <SVGInline className='map-marker' svg={mapMarker} /><div className='distance'>{distance} miles</div>
          </div>
        }
        {
          rating && rating.Rating &&
          <div className={distance && rating && rating.Rating ? 'ratings-aggregate-component divider' : 'ratings-aggregate-component'}>
            <StarBar rating={rating.Rating} />
            <a className='reviews-link' href={`${profilePageURL}?rar=true`} target='_self'>
              <div className='link-to-reviews'>{rating.Rating} ({rating.NumberOfRatings})</div>
            </a>
          </div>
        }
      </div>
    ) : null
  }

  return (
    <div className={`provider-card-container ${cardOptions.hidePhoto ? 'hide-photo' : ''}`} id={`provider-card-${provider.npi}`}>
      {
        provider.videoUrl &&
        <button className='doctor-video' title='Doctor Video' onClick={() => { launchVideo(provider.videoUrl) }}>
          <div className='icon'><SVGInline accessibilityDesc='Play Video Button' svg={playButton} /></div>
        </button>
      }
      <div className='photo-and-info-container'>
        <div className='mobile-top-container'>
          {!cardOptions.hidePhoto && <a className='img-profile-url' id={provider.npi} href={profilePageURL} target='_self' onClick={profileClick} aria-label={provider.title + ' profile'}>
            <div className='img-wrapper'>
              {hasDoctorPhoto
                ? <LazyLoadImage // use normal <img> attributes as props
                  alt={provider.title}
                  src={provider.photoUrl}
                  onClick={profileClick}
                  aria-label={provider.title + ' profile'} />
                : <img className='doc-img default-photo' alt={provider.title} aria-label={provider.title + ' profile'}src={URLS.defaultPhotoUrl} />
              }
            </div>
          </a>}
          <div className='badge-name-ratings-container'>
            {provider.isAcceptingPatients && <InfoBadge className='doctor-card-badge' text='Accepting New Patients' />}
            <a className='title-profile-url' href={profilePageURL} onClick={profileClick} target='_self'>
              <div className={'title'}>{provider.title}</div>
            </a>
            {!cardOptions.hideRatings && renderDistanceAndOrRatings()}
          </div>
        </div>
        <div className='specialties-address-container'>
          <div className='specialties'>{provider.specialties && provider.specialties.join(', ')}</div>
          {!cardOptions.hidePractice && <div className='address'>{provider.address && provider.address.lbl}</div>}
        </div>
        {
          (sort === 'First Available' || cardOptions.displayAvailability) && provider.schedStartDates && provider.schedStartDates[0] != null &&
          <div className='first-appointment-container'>
            First Available Appt: <span className='appointment-time'>{moment(provider.schedStartDates[0].Date + ' ' + provider.schedStartDates[0].Time, 'YYYY-MM-DD hh:mm:ss').format('h:mm A dddd, MMM. Do').replace(':00', '')}</span>
          </div>}
      </div>
      <div className='card-footer'>
        <div className='footer-container'>
          {
            !cardOptions.hidePhone && provider.phone &&
            <div className={`phone-number ${!hasScheduleOnline ? 'alone' : ''}`}>
              <a href={`tel:${cleanNumber(provider.phone)}`}><span onClick={() => clickToCall()}>{provider.phone}</span></a>
            </div>
          }
          {
            hasScheduleOnline &&
            <button className={`schedule-link ${cardOptions.hidePhone || !provider.phone ? 'alone' : ''}`} name='Scheduling Button' onClick={scheduleClick}>
              <span className='schedule-content'>SCHEDULE</span>
            </button>
          }
        </div>
      </div>
    </div>
  )
}

export default ProviderCard
