import React from 'react'
import SVGInline from 'react-svg-inline'
import closeIcon from '../../assets/x.svg'
import './modal.scss'

export default class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.allElementsToManipulate = document.querySelectorAll('.display-modal-id')
    this.rootHtmlElement = document.documentElement
  }

  componentWillUnmount () {
    this.removeModalOverlayClass()
  }

  render () {
    let modalDisplayClass = this.props.showModal ? 'shown-modal' : 'hidden-modal'
    let closeButtonClass = this.props.hideCloseButton ? 'hidden-close-button' : 'close-button'
    this.props.showModal ? this.addModalOverlayClass() : this.removeModalOverlayClass()

    return (
      <div className={'modal ' + modalDisplayClass + ' ' + this.props.customClass} >
        <div className='background' onTouchMove={this.preventMobileScrolling}>
          <div className='modal-content'>
            <div className={closeButtonClass} onClick={() => { if (!this.props.hideCloseButton) this.props.hideFunction() }}>
              <SVGInline svg={closeIcon} />
            </div>
            {this.props.showModal ? this.props.children : '' }
          </div>
        </div>
      </div>
    )
  }

  preventMobileScrolling (event) {
    if (event.srcElement.className === 'reasons' || event.srcElement.className.includes('chip')) {
      return
    }

    event.preventDefault()
  }

  closeModalOnBackButton () {
    this.props.hideFunction(true)
  }

  addModalOverlayClass () {
    Array.prototype.forEach.call(this.allElementsToManipulate, element => element.classList.add('display-modal-overlay'))
    this.rootHtmlElement.classList.add('no-scroll')
    window.onpopstate = () => this.closeModalOnBackButton()
  }

  removeModalOverlayClass () {
    Array.prototype.forEach.call(this.allElementsToManipulate, element => element.classList.remove('display-modal-overlay'))
    this.rootHtmlElement.classList.remove('no-scroll')
  }
}
